.navbar {

	background-color: $lightblue !important;
	
	&.bg-white {
		background-color: $white !important;
	}
	img {
		max-height: 2.5*$spacer;
	}
	
	.navbar-brand-white {
		display: none;
	}

	.navbar-brand-color {
		display: inline-block;
	}

	.navbar-collapse {
		height: 0 !important;
		&.show{
			@include respond-to('lg') {
				display: none !important;
			}
			transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
			overflow-y: auto;
			position: absolute;
			width: 100%;
			height: 100vh !important;
			top: 0;
			left: 0;
			background-color: $lightblue !important;
			padding: 2*$spacer 2*$spacer;
			.dropdown-menu {
				background-color: $lightblue;
				border: none;
				.dropdown-item{
					padding: 1*$spacer 1.25*$spacer;
					font-size: 1.1*$font-size-base;
					&:hover,
					&:focus {
						text-decoration: underline;
						background: transparent;
					}
				}
			}
			.navbar-nav {
				.nav-link {
					padding: 1*$spacer 0;
					font-size: 1.25*$font-size-base;
					color: $gray-900;
					&.active {
						color: $orange;
					}

					&:hover,
					&:focus {
						text-decoration: underline;
					}
				}
			}
			.btn {
				font-size: $font-size-lg;
			}
			.nav-close {
				width: 3*$spacer;
				height: 3*$spacer;
				line-height: 3*$spacer;
				position: fixed;
				color: $gray-700;
				font-size: 2*$font-size-lg;
				right: $spacer;
				top: 2*$spacer;
				
			}
		}
	}

	.navbar-nav {
		.nav-link {
			color: $gray-900;

			&.active {
				color: $orange;
			}

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}

	.navbar-toggler {
		border: none !important;
	}

	&.navbar-dark {
		background-color: transparent !important;
		.navbar-brand-color {
			display: none;
		}
		.navbar-brand-white {
			display: inline-block;
		}
		.navbar-toggler {
			border: none !important;
		}
		.navbar-nav {
			.nav-link {
				color: $gray-900;
				&.active {
					color: $white;
				}
				&:hover,&:focus {
					text-decoration: underline;
				}
			}
		}
		.btn-secondary {
			border: 2px solid $orange;
			background-color: $orange;
			color: $gray-900;
			&:hover,
			&:focus {
				border: 2px solid $orange;
				background-color: $white;
				color: $gray-900;
			}
			@include respond-to('lg') {
				background-color: $white;
				color: $gray-900;

				&:hover,
				&:focus {
					border: 2px solid $white;
					background-color: $orange;
					color: $white;
				}
			}
		}

		.btn-outline-secondary {
			border: 2px solid $gray-900;
			margin-right: $spacer;
			color: $gray-900;
			&:hover,
			&:focus {
				border: 2px solid $orange;
				background-color: $orange;
				color: $gray-900;
			}
			@include respond-to('lg') {
				color: $white;
				border: 2px solid $white;
				&:hover,
				&:focus {
					border: 2px solid $white;
					background-color: $white;
					color: $gray-900;
				}
			}
		}
	}

	.btn-secondary {
		transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
		border: 2px solid transparent;
		background-color: $orange;
		color: $white;
		&:hover,
		&:focus {
			border: 2px solid $orange;
			background-color: $white;
			color: $gray-700;
		}
	}

	.btn-outline-secondary {
		transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
		border: 2px solid $gray-700;
		margin-right: $spacer;
		color: $gray-700;
		&:hover,
		&:focus {
			border: 2px solid $orange;
			background-color: $white;
			color: $gray-900;
		}
	}
}

.home1 {
	position: sticky;
	top: 0;
	background: transparent linear-gradient(0deg, $yellow 0%, $orange 100%) 0% 0% no-repeat padding-box;
	height: 100vh;
	display: flex;
	z-index: 0;
	overflow: hidden;
	
	.img-hero {
		max-height: 68vh;
		left: 25vw;
		top: 14vh;
		margin-top: auto;
		margin-bottom: auto;
		position: absolute;
		
		@include respond-to('md') {
			top: 4*$spacer;
			left: 0;
			margin: auto;
			max-height: 80vh;
			max-width: 60%;
			position: relative;
		}
	}

	h1 {
		color: $white;
		position: absolute;
		opacity: 0;
		transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
		text-align: center;
		left: 25vw;
		top: 14vh;
		@include respond-to('md') {
			top: 50%;
			left: 50%;
		}
	}

	h2 {
		color: $white;
		position: absolute;
		opacity: 0;
		transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
		text-align: center;
		left: 25vw;
		top: 14vh;
		@include respond-to('md') {
			top: 50%;
			left: 50%;
		}
	}

	h3 {
		color: $gray-800;
		position: absolute;
		opacity: 0;
		transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
		text-align: center;
		left: 0;
		top: 14vh;
		width: 100%;
		@include respond-to('md') {
			transform-origin: 0 0;
			transform: rotate(-90deg);
			top: 50%;
			left: 50%
		}
	}

	.btn-secondary {
		color: $primary; 
		border: none;
		&:hover, &:focus {
			background-color: $primary;
			color: $white;
		}
	}

	.social-buttons {
		position: absolute;
		bottom: 0;
		left: 50%;
		display: flex;
		flex-direction: column;
		opacity: 0;
		transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
		.verticalLine {
			height: 10vh;
			border-left: solid $white;
			margin-bottom: 5*$spacer;
			margin-left: 3*$spacer;
		}
		.btn-outline-secondary {
			width: 3*$spacer;
			height: 3*$spacer;
			color: $white;
			border-radius: 5*$spacer;
			margin-bottom: $spacer;
			margin-left: 1.5*$spacer;
			&:hover, &:focus {
				color: $primary;
			}
		}
	}
}

.home2 {
	position: sticky;
	top: 0;
	height: 280vh;
	background: transparent;
	z-index: 1;
	.container-step {
		background: transparent linear-gradient(0deg, $white 25%, $lightblue 95%, rgba($lightblue, 0) 100%) 0% 0% no-repeat padding-box;
		top: 0;
		position: sticky;
		width: 100%;
		height: 100vh;
		overflow: hidden;
		.container {
			position: sticky;
			min-height: 100vh;
			display: flex;
			flex-direction: column;
		}
		
		.img-step {
			position: relative;
			width: 22vh;
			height: 22vh;
			margin: $spacer auto;
			overflow: hidden;

			@include respond-to('sm') {
				width: 30vh;
				height: 30vh;
				margin: 2*$spacer auto;
			}

			@include respond-to('md') {
				max-width: 85%;
				width: 35vh;
				height: 35vh;
			}

			@include respond-to('lg') {
				margin: auto;
				width: 45vh;
				height: 45vh;
			}

			@include respond-to('xl') {
				width: 55vh;
				height: 55h;
			}

			@include respond-to('xxl') {
				width: 60vh;
				height: 60vh;
			}

			.img-step0 {
				width: 100%;
				height: 100%;
				opacity: 1;
				position: absolute;
			}

			.img-step1 {
				width: 100%;
				height: 0;
				position: absolute;
				overflow: hidden;
				transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.4s;
			}

			.img-step2 {
				width: 0;
				height: 100%;
				position: absolute;
				overflow: hidden;
				transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1.2s;
			}

			.img-step3 {
				width: 0;
				height: 100%;
				position: absolute;
				overflow: hidden;
				transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
				direction: rtl;
				right: 0;
				bottom: 0;
			}
		}
		
		.step0 {
			margin-top: 4 *$spacer;
			padding-left: $spacer;
			padding-right: $spacer;
			@include respond-to('md') {
				margin-top: 6 *$spacer;
			}
			@include respond-to('lg') {
				position: absolute;
				margin-top: 0;
				top: 6*$spacer;
			}
			p {
				text-align: left;
				font-size: $font-size-sm;
				@include respond-to('md') {
					font-size: $font-size-base;
				}
			}
			a {
				color: $orange;
				text-decoration: none;
				line-height: 32px;
				font-weight: bold;

				svg {
					fill: $orange;
					animation-name: heartbeat;
					animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
					animation-iteration-count: infinite;
					animation-duration: 1s;
				}
			}
		}
		.step1 {
			opacity: 0;
			transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.4s;
			padding-left: $spacer;
			padding-right: $spacer;
			@include respond-to('lg') {
				opacity: 1;
				padding-left: 0;
				padding-right: 0;
				position: absolute;
				top: 0;
				left: 0;
				margin-top: 25%;
				margin-left: 1*$spacer;
			}

			p {
				text-align: left;
				transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.4s;
				font-size: $font-size-sm;
				margin-bottom: 0.5*$spacer;
				@include respond-to('sm') {
					margin-bottom: $spacer;
				}
				@include respond-to('md') {
					line-height: 2*$font-size-base;
					font-size: $font-size-base;
				}
				@include respond-to('lg') {
					margin-left: -300%;
				}
				span {
					color: $orange;
					font-weight: bold;
				}
			}
			ol {
				transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.4s;
				font-size: $font-size-sm;
				@include respond-to('md') {
					font-size: $font-size-base;
				}
				@include respond-to('lg') {
					margin-left: -300%;
					max-width: 250px;
				}
			}
		}
		.step2 {
			opacity: 0;
			transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1.2s;
			overflow: hidden;
			padding-left: $spacer;
			padding-right: $spacer;
			@include respond-to('lg') {
				opacity: 1;
				padding-left: 0;
				padding-right: 0;
				position: absolute;
				width: 100%;
				left: 0;
				bottom: 4*$spacer;
			}
			p {
				transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1.2s;
				font-size: $font-size-sm;
				margin-bottom: 0.5*$spacer;
				@include respond-to('sm') {
					margin-bottom: $spacer;
				}
				@include respond-to('md') {
					line-height: 2*$font-size-base;
					font-size: $font-size-base;
				}
				@include respond-to('lg') {
					text-align: center;
				}
				span {
					color: $blue;
					font-weight: bold;
				}
			}
		}
		.step3 {
			opacity: 0;
			transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
			padding-left: $spacer;
			padding-right: $spacer;
			@include respond-to('lg') {
				opacity: 1;
				padding-left: 0;
				padding-right: 0;
				position: absolute;
				margin-top: 25%;
				top: 0;
				right: 0;
				margin-right: 3*$spacer;
			}

			p {
				transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
				font-size: $font-size-sm;
				margin-bottom: 0.5*$spacer;
				@include respond-to('sm') {
					margin-bottom: $spacer;
				}
				@include respond-to('md') {
					line-height: 2*$font-size-base;
					font-size: $font-size-base;
				}
				@include respond-to('lg') {
					text-align: right;
					margin-right: -300%;
					max-width: 232px;
					float: right;

				}
				span {
					line-height: $font-size-sm;
					color: $green;
					font-weight: bold;
				}
			}
		}
	}
}
.home3 {
	position: sticky;
	top: 0;
	height: 280vh;
	background: transparent;
	z-index: 1;

	.container-step {
		background: transparent linear-gradient(0deg, $lightblue 25%, $yellow 95%, rgba($yellow, 0));
		top: 0;
		position: sticky;
		width: 100%;
		height: 100vh;
		overflow: hidden;

		.container {
			position: relative;
			height: 100vh;
			display: flex;
			flex-direction: column;
			p {
				@include respond-to('lg') {
					max-width: 40%;
					font-size: $font-size-lg;
				}
				@include respond-to('xl') {
					max-width: 30%;
				}
			}
			.card {
				border-radius: 2*$spacer;
				padding: $spacer;
				transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
				width: 100%;
				height: 80vh;
				position: absolute;
				top: 40vh;
				opacity: 0;
				padding: 1.5*$spacer;
				overflow: hidden;
				box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
				@include respond-to('lg') {
					width: 50%;
					height: 52vh;
					min-height: 28*$spacer;
					position: absolute;
					top: 20vh;
					bottom: 0;
					right: 0;
					opacity: 0;
					padding: 1.5*$spacer 2.5*$spacer;
					transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
				}
				@include respond-to('xl') {
					height: 47vh;
					
				}
				@include respond-to('xxl') {
					height: 42vh;
				}
				&.card1 {
					background-color: #215A7D;
					color: $white;
					
				}
				&.card2 {
					background-color: #CE6D0B;
					color: $white;
					@include respond-to('lg') {
						margin-top: 4*$spacer;
						margin-right: 3*$spacer;
					}
					img {
						max-width: 42%;

						@include respond-to('lg') {
							max-width: 22.5%;
						}

						@include respond-to('xl') {
							max-width: 25%;
						}
					}
				}
				&.card3 {
					background-color: #008177;
					color: $white;
					@include respond-to('lg') {
						margin-top: 8*$spacer;
						margin-right: 6*$spacer;
					}
					img {
						max-width: 32%;

						@include respond-to('lg') {
							max-width: 22.5%;
						}

						@include respond-to('xl') {
							max-width: 20%;
						}
					}
				}
				&.card4 {
					background-color: $orange;
					color: $white;
					@include respond-to('lg') {
						margin-top: 12*$spacer;
						margin-right: 9*$spacer;
					}
				}
				&:not(.opened) {
					cursor: pointer;
					&:hover {
						@include respond-to('lg') {
							animation: peek 0.75s forwards;
						}
					}
				}

				&.opened {
					z-index: $zindex-tooltip;
					top: $spacer;
					width: 100%;
					height: 85vh;
					top: 15%;
					left: 0;
					margin: 0 !important;
					transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
					overflow: auto;
					border-radius: 2*$spacer 2*$spacer 0 0;
					@include respond-to('lg') {
						width: 60%;
						height: 65vh;
						transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
						top: 20%;
						left: 0;
						margin: 0 auto !important;
						overflow: auto;
						border-radius: 2*$spacer;
					}
					.close {
						display: block;
						position: absolute;
						top: 1rem;
						right: 2rem;
						font-size: 3rem;
					}
					h4 {
						font-size: $h2-font-size;
					}
					p {
						font-size: $font-size-lg;
					}
				}
				.close {
					display: none;
				}
				a {
					color: $white;
					text-decoration: underline;
				}
				img {
					position: absolute;
					bottom: 2*$spacer;
					right: 2*$spacer;
					max-width: 32%;
					display: none ;
					@include respond-to('lg') {
						max-width: 22.5%;
					}
					@include respond-to('xl') {
						max-width: 25%;
					}
				}
				ul {
					list-style: none;
					li{
						&:before{
							display: inline-block;
							content: "-";
							width: 1em;
							margin-left: -1em;
						}
					}
				}
				.btn-secondary {
					display: block !important;
					color: $gray-700 !important;
					margin: $spacer auto $spacer 0;
					text-decoration: none;
					width: 15*$spacer;
					&:hover {
						border: 1px solid $white;
						background-color: transparent;
						color: $white !important;
					}
					@include respond-to('xl') {
						margin: 0 $spacer $spacer;
						float: left;
					}
				}
				.btn-outline-secondary {
					display: block !important;
					color: white;
					margin: $spacer auto $spacer 0;
					text-decoration: none;
					width: 15*$spacer;
					&:hover {
						color: $gray-700 !important;
					}
					@include respond-to('xl') {
						margin: 0 $spacer $spacer;
						float: left;
					}
				}
			}
			.card0 {
				margin-top: 4 *$spacer;
				padding-left: $spacer;
				padding-right: $spacer;

				@include respond-to('md') {
					margin-top: 6 *$spacer;
				}

				@include respond-to('lg') {
					position: absolute;
					margin-top: 0;
					top: 6*$spacer;
				}

				p {
					text-align: left;
					font-size: $font-size-sm;

					@include respond-to('md') {
						font-size: $font-size-base;
					}
				}

				a {
					color: $white;
					text-decoration: none;
					line-height: 32px;

					svg {
						fill: $white;
						animation-name: heartbeat;
						animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
						animation-iteration-count: infinite;
						animation-duration: 1s;
					}
				}
			}
		}
	}
}

.home4 {
	background: $white;
	z-index: 4;
	opacity: 1;
	@include respond-to('lg') {
		min-height: 160vh;
		opacity: 0;
	}
	h2 {
		margin: 6*$spacer auto 2*$spacer;
		text-align: center;
	}

	h3 {
		text-align: center;
	}
	h4 {
		text-align: center;
		font-size: $font-size-base;
	}

	.container-step {
		background: transparent linear-gradient(0deg, $white 95%, rgba($white, 0) 100%) 0% 0% no-repeat padding-box;
		top: 0;
		position: sticky;
		width: 100%;
		min-height: 100vh;
		overflow: hidden;
		@include respond-to('lg') {
			height: 100vh;
		}
	}
	
	.office {
		margin: 1*$spacer;
		cursor: pointer;
		.city {
			position: relative;
			padding: 1*$spacer;
			border-radius: 120*$spacer;
			border: 3px solid $gray-700;
			display: flex;
			margin: 0 auto;
			overflow: hidden;
			width: 7.5*$spacer;
			height: 7.5*$spacer;
			margin-bottom: $spacer;
			@include respond-to('md') {
				width: 12*$spacer;
				height: 12*$spacer;
			}
			
			img {
				top: 0;
				position: absolute;
				width: 300%;
				margin: 0;
			}
		}
		.content {
			padding: 3*$spacer;
			left: 0;
			top: 100vh;
			width: 100%;
			position: fixed;
			border-radius: 2*$spacer;
			width: 100%;
			height: auto;
			min-height: 80vh;
			transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1.25s;
			background-color: $gray-700;
			h5 {
				color: $white;
				text-align: center;
			}
			p {
				color: $white;
				text-align: center;
			}
			a {
				color: $white;
			}
			.time {
				font-size: 1.5*$font-size-lg;
			}
		}
		&.open {
			.content {
				top: 20vh;
				left: 0;
				z-index: 101;
				.close {
					position: absolute;
					top: 1*$spacer;
					right: 2*$spacer;
					font-size: 1.5*$font-size-lg;
				}
				a {
					color: $white;
				}
			}
		} 
		&.au {
			.city {
				border-color: #BB1313;
			}
			img {
				left: -111px;
				top: -16px;
				@include respond-to('md') {
					left: -173px;
					top: -25px;
				}
			}
			&.open {
				.content {
					background-color: #BB1313;
				}
			}
		}
		&.uk {
			.city {
				border-color: #FF890F;
			}
			img {
				width: 393%;
				left: -160px;
				top: -36px;
				@include respond-to('md') {
					left: -256px;
					top: -59px;
				}
			}
			&.open {
				.content {
					background-color: #FF890F;
				}
			}
		}

		&.co {
			.city {
				border-color: #05659F;
			}

			img {
				left: -94px;
				top: -18px;
				width: 289%;
				@include respond-to('md') {
					left: -113px;
					top: -30px;
				}
			}
			&.open {
				.content {
					background-color: #05659F;
				}
			}
		}

		&.br {
			.city {
				border-color: #1AE679;
			}

			img {
				width: 314%;
				left: -236px;
				top: -24px;
				@include respond-to('md') {
					left: -376px;
					top: -39px;
				}
			}

			&.open {
				.content {
					background-color: #1AE679;
				}
			}
		}

		&.cl {
			.city {
				border-color: #0192E0;
			}

			img {
				left: -99px;
				top: -16px;
				width: 314%;
			}

			&.open {
				.content {
					background-color: #0192E0;
				}
			}
		}

		&.mx {
			.city {
				border-color: #01E0D9;
			}

			img {
				width: 380%;
				left: -213px;
				top: -27px;
			}

			&.open {
				.content {
					background-color: #01E0D9;
				}
			}
		}

		&.cn {
			img {
				width: 380%;
				left: -235px;
				top: -43px;
			}
		}

		&.in {
			img {
				width: 409%;
				left: -188px;
				top: -33px;
			}
		}
	}

	.container-distribution-map {
		display: flex;
		height: 70vh;
		overflow: visible;
		z-index: 9999 !important;
	}

	.distribution-map {

		position: relative;
		padding: 0;
		box-sizing: border-box;
		margin: auto;
		
		@include respond-to('lg') {
			width: 90%;
		}

		.map{
			max-width: 100%;
			position: relative;
			margin: 0;
			padding: 0;
			opacity: 1;
		}

		.map-au {
			max-width: 100%;
			position: absolute;
			margin: 0;
			padding: 0;
			opacity: 0;
			top: 0;
			left: 0;
			transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1.25s;
		}

		.map-uk {
			max-width: 100%;
			position: absolute;
			margin: 0;
			padding: 0;
			opacity: 0;
			top: 0;
			left: 0;
			transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1.25s;
		}

		.map-co {
			max-width: 100%;
			position: absolute;
			margin: 0;
			padding: 0;
			opacity: 0;
			top: 0;
			left: 0;
			transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1.25s;
		}
		.map-br {
			max-width: 100%;
			position: absolute;
			margin: 0;
			padding: 0;
			opacity: 0;
			top: 0;
			left: 0;
			transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1.25s;
		}

		.map-mx {
			max-width: 100%;
			position: absolute;
			margin: 0;
			padding: 0;
			opacity: 0;
			top: 0;
			left: 0;
			transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1.25s;
		}

		.map-cl {
			max-width: 100%;
			position: absolute;
			margin: 0;
			padding: 0;
			opacity: 0;
			top: 0;
			left: 0;
			transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1.25s;
		}

		.map-point {
			cursor: pointer;
			position: absolute;
			width: 22.5px;
			height: 30px;
			padding: 0;
			margin: 0;
			opacity: 0;
			transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1.25s;
			&:hover {
				.line {
					margin-top: -15*$spacer;
					height: 15*$spacer;
					img {
						transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1.25s;
						top: 4*$spacer;
					}
				}
				.content {
					display: block;
					height: 19*$spacer;
					width: 15*$spacer;
					margin-left: -15*$spacer;
					margin-top: -15*$spacer;
					z-index: 100;
					transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
					h5 {
						opacity: 1;
						transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
						transition-delay: 0.25s;
					}
					p {
						margin-top: $spacer;
						opacity: 1;
						transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
						transition-delay: 0.25s;
					}
					.btn {
						opacity: 1;
						transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
						transition-delay: 0.25s;
					}
				}
			}

			.address {
				font-size: $font-size-sm;
			}
			
			.fa-map-marker-alt {
				position: absolute;
				font-size: 1.5*$font-size-lg;
				margin-left: 0;
				margin-top: 0;
			}
			.time {
				font-size: $font-size-lg;
			}
			a {
				color: $white;
				text-decoration: none;
			}
			.line {
				position: absolute;
				width: 2.25*$spacer;
				margin-top: 0;
				height: 0;
				overflow: hidden;
				img {
					position: absolute;
					top: 25*$spacer;
					left: 0;
					width: 100%;
					height: auto;
				}
			}
			.content {
				position: absolute;
				display: none;
				width: 0;
				height: 0;
				margin: 0;
				padding: 1.5*$spacer;
				h5 {
					opacity: 0;
					color: $white;
					text-align: center;
				}
				p{
					opacity: 0;
					color: $white;
					text-align: center;
				}
				.btn {
					opacity: 0;
					background-color: $white;
					color: $gray-700;
					border-radius: 5*$spacer;
					margin-top: 2*$spacer;
				}
			}
			&.cn {
				opacity: 1;
				top: 42%;
				right: 21%;

				.fa-map-marker-alt {
					color: $white;
				}

				.content {
					background-color: $gray-500;
				}

				.btn {
					color: $gray-700;
				}
			}
			&.in {
				opacity: 1;
				top: 49%;
				right: 30%;

				.fa-map-marker-alt {
					color: $white;
				}

				.content {
					background-color: $gray-500;
				}

				.btn {
					color: $gray-700;
				}
			}
			&.au {
				bottom: 20%;
				right: 11%;
				.fa-map-marker-alt {
					color: #BB1313;
				}
				.content {
					background-color: #BB1313;
				}
				.btn {
					color: #BB1313;
				}
			}
			&.uk {
				top: 31%;
				left: 45.5%;

				.fa-map-marker-alt {
					color: #FF890F;
				}
				.content {
					background-color: #FF890F;
				}
				.btn {
					color: #FF890F;
				}
				.line{
					top: 3*$spacer;
					
					img {
						top: -30*$spacer;
					}
				}
				&:hover {
					.line {
						margin-top: 0;
						
						img {
							transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1.25s;
							top: 0;
						}
					}
					.content {
						margin-top: 0;
					}
				}
			}

			&.mx {
				top: 49%;
				left: 18.5%;
				
				.fa-map-marker-alt {
					color: #01E0D9;
				}

				.content {
					background-color: #01E0D9;
				}

				.btn {
					color: #01E0D9;
				}
				.line {
					width: 2.5*$spacer;
					left: 2*$spacer;
				}

				&:hover {
					.content {
						margin-left: 4*$spacer;
					}
				}
			}
			&.co {
				top: 56%;
				left: 25.5%;

				.fa-map-marker-alt {
					color: #05659F;
				}
				.content {
					background-color: #05659F;
				}
				.btn {
					color: #05659F;
				}
				.line {
					width: 2.5*$spacer;
					left: 2*$spacer;
				}

				&:hover {
					.content {
						margin-left: 4*$spacer;
					}
				}
			}
			&.cl {
				top: 71%;
				left: 27%;
				

				.fa-map-marker-alt {
					color: #0192E0;
				}
				.content {
					background-color: #0192E0;
				}
				.btn {
					color: #0192E0;
				}
				.line {
					width: 2.5*$spacer;
					left: 2*$spacer;
				}

				&:hover {
					.content {
						margin-left: 4*$spacer;
					}
				}
			}
			&.br {
				top: 65%;
				left: 32%;

				.fa-map-marker-alt {
					color: #1AE679;
				}

				.content {
					background-color: #1AE679;
				}

				.btn {
					color: #1AE679;
				}

				.line {
					width: 2.5*$spacer;
					left: 2*$spacer;
				}
				&:hover {
					.content {
						margin-left: 4*$spacer;
					}
				}
			}

		}
	}
}
.home5 {
	background: $white;
	z-index: 3;

	.swiper-container-outside {
		width: 100%;
		overflow: visible;
		
	}
	.testimonial {
		overflow: hidden;
	}
	.swiper-container-outer{
		
	}
	 .swiper-container,
	 .swiper-container-desktop {
	 	width: 100%;
	 	margin-top: 2*$spacer;
		margin-bottom: 2*$spacer;
		align-items: center;
		
		
		.swiper-slide{
			
			&-active {

			}
		}
		
	 }
	 h3 {
		 display: inline-block;
		 margin-right: 1*$spacer;
		 line-height: 3*$spacer;
	 }
	 .swiper-button-next,
	 .swiper-button-prev,
	 .button-prev,
	 .button-next {
		 display: inline-block;
		 text-align: center;
		 width: 3*$spacer;
		 height: 3*$spacer;
		 border: 1px solid #0192E0;
		 color: #0192E0;
		 border-radius: 100*$spacer;
		 line-height: 3*$spacer;
		 font-size: 1.5*$font-size-base;
		 &.swiper-button-disabled {
			 opacity: 0.3;
		 }
		 &.disabled {
		 	opacity: 0.3;
		 }
	 }

	 .swiper-slide {
	 	display: flex;
	 	justify-content: center;
	 	align-items: center;

		h4 {
			margin-top: $spacer;
			font-size: $font-size-sm;
		}

		h5 {
			color:#0192E0;
			font-size: $font-size-sm;
		}
		
		img {
			width: 6*$spacer;
			height: 6*$spacer;
			border-radius: 100*$spacer;
			border: 1px solid $gray-500;
		}
		.quote {
			position: absolute;
			background-color: $white;
			width: 3*$spacer;
			height: 3*$spacer;
			left: 4*$spacer;
			top: 2*$spacer;
			border-radius: 100*$spacer;
			text-align: center;
			align-self: first baseline;
			.fas {
				line-height: 3*$spacer;
				color: #0192E0;
				font-size: 1.5*$font-size-base;
			}	
			&-image {
				position: relative;
			}
		}
		p {
			margin-left: 2*$spacer;
		}
		
	}
}
.home6 {
	background: $white;
	z-index: 3;
	img {
		height: 4*$spacer;
		margin: $spacer 0.5*$spacer;
	}
}

footer {
	a{
		color: $gray-700;
		line-height: 3*$spacer;
		&:hover,&:focus {
			color: $gray-700;
		}
	}
	.fa {
		color: $gray-700;
	}
	.social-buttons {
		a {
			border: 1px solid $gray-700;
			border-radius: 100*$spacer;
			height: 3*$spacer;
			width: 3*$spacer;
			line-height: 1.8*$spacer;
			&:hover {
				border: 1px solid $gray-700;
				background-color: $gray-700;
				.fa {
					color: $white !important;
					margin: 0 i !important;
				}
			}
		}
	}
}

.back-top {
	opacity: 0;
	position: fixed;
	bottom: -2.5*$spacer;
	left: 50%;
	z-index: $zindex-dropdown;
	transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
	@include respond-to('md') {
		opacity: 0;
	}
	.btn {
		width: 5*$spacer;
		height: 5*$spacer;
		border-radius: 5*$spacer;
		animation: bounce-arrow infinite;
		transition: cubic-bezier(0.075, 0.82, 0.165, 1) 10s;
		.fa {
			animation-name: bounce-arrow;
			animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
			animation-iteration-count: infinite;
			animation-duration: 3s;
		}
	}
}

.contents{
	.content {
		display: none;
		position: fixed;
		top: 0;
		background-color: $lightblue;
		width: 100%;
		height: 100vh;
		z-index: $zindex-tooltip;
		transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
		animation: rightLeft 0.25s forwards;
		h1{
			color: $orange;
			opacity: 0.3;
			font-size: 3*$h1-font-size;
			display: none;
			@include respond-to('md') {
				display: inline-block;
			}
		}
		h2 {
			
			color: $orange;
			font-size: $h1-font-size;
			font-weight: bold;
			@include respond-to('md') {
				position: absolute;
				bottom: 1.3*$spacer;
				left: 1.5*$spacer;
			}
		}
		table {
			border-color: $gray-500;
		}
		
		&.open {
			display: block;
			top: 0;
			left: 0;
			animation: leftRight 0.25s forwards;
			.right {
				right: 0;
				animation: leftRight2 0.25s forwards;
			}
		}
		.logo {
			margin-top: 2*$spacer;
			margin-bottom: 3*$spacer;
			max-width: 80%;
			width: 10*$spacer;
		}
		iframe {
			width: 100%;
			height: 20*$spacer;
			background-color: transparent;
			@include respond-to('sm') {
				height: 10*$spacer;
			}
		}
		.left {
			height: 100vh;
			overflow-y: auto;
			.container {
				padding-bottom: 7*$spacer;
			}
			.step-text{
				display: inline-block;
				width: 90%;
			}
			.step-number {
				display: inline-block;
				width: 7%;
				vertical-align: top;
				@include respond-to('md') {
					font-size: $font-size-lg;
				}
			}
			.step1 {
				color: $orange;
			}

			.step2 {
				color: $blue;
			}

			.step3 {
				color: $green;
			}
		}
		.right {
			position: fixed;
			top: 0;
			right: 0;
			@include respond-to('md') {
				background-color: $gray-700;
				height: 100vh;
			}
			.social-buttons {
				position: absolute;
				bottom: 0;
				width: 100%;
				display: none;
				@include respond-to('md') {
					display: flex;
				}
				@include respond-to('lg') {
					bottom: 5*$spacer;
				}
				a {
					width: 3*$spacer;
					height: 3*$spacer;
					border-radius: 100*$spacer;
					margin: 0 auto 1*$spacer;
					
				}
				
			}

			.close-btn{
				
				display: block;
				left: 0;
				width: 2*$spacer;
				height: 2*$spacer;
				margin: 2*$spacer 2*$spacer 2*$spacer 0;
				border-radius: 100*$spacer;
				background-color: $gray-700;
				border: 2px solid $white;
				box-shadow: 0 0 0 2px $white,
				0 0 0 4px $gray-800,
				0 0 0 6px $white;
				color: $white;
				text-align: center;
				line-height: 1.75*$spacer;
				@include respond-to('md') {
					margin: 2*$spacer auto;
				}
				&:hover, &:focus {
					background-color: $white;
					color: $gray-700;
				}
			}
		}
		.request-demo {
			display: none;
		}
	}
}

.cookies-notice {
	display: none;
	width: 100%;
	position: fixed;
	bottom: 0;
	background: transparent linear-gradient(0deg, $gray-700 25%, $gray-800 65%, rgba($gray-900, 0));
	z-index: $zindex-dropdown+100;
	padding-top: 2.5*$spacer;
	padding-bottom: 0.5*$spacer;
	p {
		margin-top: 0.5*$spacer;
		color: $white;
	}
	.btn {
		margin-top: 1.5*$spacer;
		@include respond-to('lg') {
			margin-top: 0;
		}
	}
}

 @keyframes bounce-arrow {
 	0% {
 		transform: scale(1, 1) translateY(0);
 	}
 	10% {
 		transform: scale(1.1, .9) translateY(0);
 	}
 	30% {
 		transform: scale(.9, 1.1) translateY(-8px);
 	}
 	50% {
 		transform: scale(1.05, .95) translateY(0);
 	}
 	57% {
 		transform: scale(1, 1) translateY(-3px);
 	}
 	64% {
 		transform: scale(1, 1) translateY(0);
 	}
 	100% {
 		transform: scale(1, 1) translateY(0);
 	}
 }

 @keyframes heartbeat {
 	0% {
 		transform: scale(1, 1);
 	}

 	10% {
 		transform: scale(1.05, 1.05);
 	}

 	30% {
 		transform: scale(1.1, 1.1);
 	}

 	50% {
 		transform: scale(1.15, 1.15);
 	}

 	57% {
 		transform: scale(1.1, 1.1);
 	}

 	64% {
 		transform: scale(1.05, 1.05);
 	}

 	100% {
 		transform: scale(1, 1);
 	}
 }

 @keyframes peek {
 	0% {
 		transform: translate(0px, 0px) rotate(0deg);
 	}

 	100% {
 		transform: translate(0*$spacer, -2*$spacer) rotate(0deg);
 	}
 }

 @keyframes shake {
 	0% {
 		transform: translate(1px, 1px) rotate(0deg);
 	}

 	5% {
 		transform: translate(-1px, -2px) rotate(-1deg);
 	}

 	10% {
 		transform: translate(-3px, 0px) rotate(1deg);
 	}

 	15% {
 		transform: translate(3px, 2px) rotate(0deg);
 	}

 	20% {
 		transform: translate(1px, -1px) rotate(1deg);
 	}

 	25% {
 		transform: translate(-1px, 2px) rotate(-1deg);
 	}

 	30% {
 		transform: translate(-3px, 1px) rotate(0deg);
 	}

 	35% {
 		transform: translate(3px, 1px) rotate(-1deg);
 	}

 	40% {
 		transform: translate(-1px, -1px) rotate(1deg);
 	}

 	45% {
 		transform: translate(1px, 2px) rotate(0deg);
 	}

 	50% {
 		transform: translate(1px, -2px) rotate(-1deg);
 	}
 }

 @keyframes leftRight {
 	0% {
 		left: -100%;
 	}

 	50% {
 		left: -50%;
 	}

 	100% {
 		left: 0;
 	}
 }

 @keyframes leftRight2 {
 	0% {
 		right: 100%;
 	}

 	50% {
 		right: 50%;
 	}

 	100% {
 		right: 0;
 	}
 }

 @keyframes rightLeft {
 	0% {
 		left: 0;
 	}

 	50% {
 		left: -50%;
 	}

 	100% {
 		left: -100%;
 	}
 }

 @keyframes rightLeft2 {
 	0% {
 		right: 0;
 	}

 	50% {
 		right: 50%;
 	}

 	100% {
 		right: 100%;
 	}
 }